<template>
    <div class="container">
      <div>
        <div class="top" id="bloc-0">
          <voice-header></voice-header>
        </div>
    <div class="main privacy-policy">
        <h1>Privacy Policy for TiktokVoice.net</h1>
        <p>Effective Date: July 30, 2024</p>
        <p>Welcome to TikTok Voice. This privacy policy explains how we collect, use, and protect your information when you use our website (
            <a href="https://tiktokvoice.net/" title="TikTok Voice Generator">https://tiktokvoice.net/</a>)
        </p>
        <h2>1. Information We Collect</h2>
        <p>We collect non-personal data through the use of web cookies.</p>
        <h2>2. Purpose of Data Collection</h2>
        <p>The data we collect is used solely for bug processing to improve our services.</p>
        <h2>3. Data Sharing</h2>
        <p>We do not share your data with any other parties.</p>
        <h2>4. Children's Privacy</h2>
        <p>We do not collect any data from children.</p>
        <h2>5. Contact Information</h2>
        <p>If you have any questions or concerns about this privacy policy, please contact us at
            <a :href="sendmail" title="TikTok Voice generator mail">tiktokaivoicetool@gmail.com</a>
        </p>
        <p>By using our website, you acknowledge that you have read and understood this privacy policy and agree to its terms.</p>
        <hr />
        <p> TikTok Voice <a href="https://tiktokvoice.net" title="TikTok Voice Generator">https://tiktokvoice.net</a></p>
        <p> <a :href="sendmail" title="TikTok Voice generator mail">tiktokaivoicetool@gmail.com</a> </p>
     </div>
        <!-- form @submit.prevent="createPortalSession">
          <input type="hidden" id="session-id" name="session_id" :value="sessionId">
          <button id="checkout-and-portal-button" type="submit">Manage your billing information</button>
        </form -->
      <div>
        <voice-footer></voice-footer>
      </div>
    </div>
    </div>
    </template>
    <script>    
    import VoiceHeader from '../components/VoiceHeader.vue';
    import { defineAsyncComponent } from 'vue'
    const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooter.vue'))

    export default {
        name: 'privacyPolicy',
        head() {
            return {
            'title': this.$i18n.t('txt2voice.title'),
            'keywords': this.$i18n.t('txt2voice.keywords'),
            'description': this.$i18n.t('txt2voice.description'),
            }
        },
        components: {
            VoiceHeader,
            VoiceFooter
        },
        computed: {
            sendmail() {
                return `mailto:${this.$i18n.t('comm.footer_mail')}`
            }
        }
    }
    </script>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  color: #444;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.privacy-policy p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy hr {
  margin: 30px 0;
  border: none;
  border-top: 1px solid #eee;
}
</style>
